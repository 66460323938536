<template>
  <div class="container">
    <div class="form-card">
      <div class="card-head">
        <router-link to="/users/user-list">Mağazalar</router-link>
        <span class="arrow">></span>

        <h4 v-if="this.$route.params.id" class="card-title">
          Mağaza Güncelle >
          {{ formData.given_name + " " + formData.family_name }}
        </h4>
        <h4 v-else class="card-title">Mağaza Ekle</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              name="store_url"
              type="text"
              @onChangeText="handleText"
            />
          </div>
          <div class="col-md-4">
            <TextTemplate
              name="security_privacy_policy_url"
              type="text"
              @onChangeText="handleText"
            />
          </div>
          <div class="col-md-4">
            <TextTemplate
              name="support_url"
              type="text"
              @onChangeText="handleText"
            />
          </div>
          <div class="col-md-4">
            <TextTemplate
              name="contact_email"
              type="text"
              @onChangeText="handleText"
            />
          </div>
          <div class="col-md-4">
            <TextTemplate
              name="ecommerce_platform"
              type="text"
              @onChangeText="handleText"
            />
          </div>
          <div class="col-md-4">
            <TextTemplate
              name="customer"
              type="select"
              :selectArray="customerList"
              @onChangeText="handleText"
            />
          </div>

          <div class="col-md-12">
            <button
              class="btn btn-filled-primary"
              @click="updateStore"
              v-if="this.$route.params.id"
            >
              Güncelle
            </button>
            <button class="btn btn-filled-primary" @click="addStore" v-else>
              Ekle
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Done
    @closeModal="doneModalClose"
    v-if="isCloseModal"
    :doneMsg="popupText"
    :doneTitle="popupTitle"
    @click="closeModal"
    link="/stores"
  />
</template>
<script>
import useValidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useValidate(),
      isCloseModal: false,
      popupText: "",
      popupTitle: "",
      formData: {
        store_url: "",
        security_privacy_policy_url: "",
        support_url: "",
        contact_email: "",
        platform_data: {},
        ecommerce_platform: "",
        customer: "",
        ideasoft_store_attributes: {},
      },
      customerList: [],
    };
  },
  validations() {
    return {};
  },

  created() {
    this.getCustomers();
    if (this.$route.params.id) {
      this.$axios
        .get(`/ecommerce-store/${this.$route.params.id}/`)
        .then(async (response) => {
          let user = response.data;
          this.formData = {};
        });
    }
  },
  methods: {
    doneModalClose(value) {
      this.isCloseModal = value;
    },
    addStore() {
      this.$axios.post("/ecommerce-store/", this.formData).then(() => {
        this.popupText = "Mağaza Eklendi";
        this.popupTitle = "Mağaza Ekleme";

        this.isCloseModal = true;
      });
    },
    updateStore() {
      this.$axios
        .put(`/ecommerce-store/${this.$route.params.id}/`, postData)
        .then(() => {
          this.popupText = "Mağaza Güncellendi";
          this.popupTitle = "Mağaza Güncelleme";
          this.isCloseModal = true;
        });
    },
    getCustomers() {
      this.$axios.get("/customer/").then((response) => {
        response.data.filter((customer) => {
          this.customerList.push({
            id: customer.id,
            name: customer.name,
          });
        });
      });
    },
    handleText(data) {
      this.formData[data.name] = data.value;
    },
  }
};
</script>